<template>
  <div>
    <h2 class="h3 card-title">All Transactions</h2>
    <div class="card">
      <div class="card-body">
        <table class="table table-responsive-md table-striped">
          <thead>
            <tr>
              <td>#</td>
              <td>User</td>
              <td>Type</td>
              <td>Amount</td>
              <td>Reference</td>
              <td>Method</td>
              <td>Date</td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(transaction, i) in transactions" :key="`transaction-${i}`">
              <td>{{ i+1 }}</td>
              <td>{{ transaction.user.name }}</td>
              <td>{{ transaction.type }}</td>
              <td>{{ transaction.currency }} {{ transaction.amount }}</td>
              <td>{{ transaction.reference }}</td>
              <td>{{ transaction.method }}</td>
              <td>{{ transaction.date }}</td>
              <td>
                <router-link :to="{ name: 'admin.transactions.show', params: { id: transaction.id }}">Details</router-link>
              </td>
            </tr>
            <tr v-if="transactions.length == 0">
              <td colspan="7">There is no data to display</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch()
  },

  data() {
    return {
      transactions: []
    }
  },

  methods: {
    fetch() {
      this.$loader.start()
      this.$axios.get('/api/v1/admin/transactions').then(response => {
        this.transactions = response.data.transactions
        this.$loader.stop()
      })
    }
  }
}
</script>